import React from "react"
import { Link } from "gatsby"

export default function Header() {
    return (
  <nav class="w-full flex items-center justify-between bg-green-700 p-6">
    <div class="items-center w-1/2 text-brown mr-6 text-center">
      <span class="font-semibold text-xl">3 Wise Monkeys</span>
    </div>
    <div class="w-1/2 mt-1 text-md flex flex-row-reverse">
        <Link to="/contact-us">
            <a class="block text-brown hover:text-grey-500 mr-4" title="Contact Us" href="contact-us">
                Contact Us
            </a>
        </Link>
        <Link to="/about">
            <a className="block text-brown hover:text-grey-500 mr-4" title="About" href="About">
                About
            </a>
        </Link>
        <Link to="/">
            <a class="block text-brown hover:text-grey-500 mr-4" title="Home" href="Home">
                Home
            </a>
        </Link>
    </div>
  </nav>
)
};