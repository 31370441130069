import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/Layout"
import View from "../components/View"
import Status from "../components/Status"

export default function Index({data}) {
    return (
        <Layout>
            <Status/>
            <View>
                <p>
                    A small consulting firm...
                    <br/>
                    <button
                        class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline float-right"
                        type="button">
                        <Link to="/app/profile">Go to your profile</Link>
                    </button>
                </p>
            </View>
        </Layout>
    )
};
export const query = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
    }
`;
