import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/Layout"
import View from "../components/View"
import Status from "../components/Status"

export default class ContactUs extends React.Component {
    state = {
        name: "",
        email: "",
        message:""
    }
    render() {
        return (
            <Layout>
                <Status/>
                <View>
                    <h1>Contact Us</h1>
                    <form method="post" action="#">
                        <label for="name">
                            Name:</label>
                            <input type="text" name="name" id="name" />
                        <br />
                        <label for="email">
                            Email</label>
                            <input type="email" name="email" id="email" />
                        <br />
                        <label for="subject">
                            Subject</label>
                            <input type="text" name="subject" id="subject" />
                        <br />
                        <label for="message">
                            Message</label>
                            <textarea name="message" id="message" rows="5" />
                        <br />
                        <button type="submit">Send</button>
                        <input type="reset" value="Clear" />
                    </form>
                </View>
            </Layout>
        )
    }
}
export const query = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
    }
`;
