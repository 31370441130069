import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/Layout"
import View from "../components/View"
import Status from "../components/Status"

export default function About({data}) {
    return (
        <Layout>
            <Status/>
            <View>
                <h1>About</h1>
                <p>The&nbsp;<strong>three wise monkeys</strong>&nbsp;are a&nbsp;
                    <a href="https://en.wikipedia.org/wiki/Japan">Japanese</a>&nbsp;pictorial&nbsp;<a href="https://en.wikipedia.org/wiki/Maxim_(philosophy)">maxim</a>, embodying the&nbsp;<a href="https://en.wikipedia.org/wiki/Proverb">proverbial</a>&nbsp;principle&nbsp;<strong>“see no evil, hear no evil, speak no evil”</strong>.<sup><a href="https://en.wikipedia.org/wiki/Three_wise_monkeys#cite_note-1">[1]</a></sup>&nbsp;The three monkeys are&nbsp;<strong>Mizaru</strong>, covering his eyes, who sees no evil;&nbsp;<strong>Kikazaru</strong>, covering his ears, who hears no evil; and&nbsp;<strong>Iwazaru</strong>, covering his mouth, who speaks no evil.  –  <a href="https://en.wikipedia.org/wiki/Three_wise_monkeys">https://en.wikipedia.org/wiki/Three_wise_monkeys</a> </p>
            </View>
        </Layout>
    )
};
export const query = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
    }
`;
